import { Component, Prop, PropSync, Ref, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Logger } from 'fsts';
import rfd, { ReportFilterData } from '@/shared/model/smallPayloadModels/reportFilterData';
import { SaveList } from '@/shared/model/saveList';
import reportDown, { ReportDownload } from '@/shared/model/reportDownload';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('document-comment-dialog');
const articleModule = namespace('article');
const saveListModule = namespace('saveList');
const reportDownloadModule = namespace('reportDownload');

@Component({
  name: 'report-dialog',
  components: {},
})
export default class ReportDialog extends Vue {
  @Ref('observer-report-dialog-form')
  private observerReportDialogForm!: any;

  @articleModule.Action('getArticlesReport')
  private actionGetArticlesReport!: any;

  @articleModule.Action('getArticlesSuppliersFiltered')
  private actionGetArticlesSuppliers!: any;
  @articleModule.Getter('getArticlesSuppliers')
  private getArticlesSuppliers!: any;

  @saveListModule.Action('getSaveList')
  private actionGetSaveList!: any;

  @reportDownloadModule.Action('updateReportDownload')
  private actionUpdateReportDownload!: any;

  @Prop({ default: false })
  private dialog!: boolean;

  @Prop()
  private listModel!: any;

  private model!: SaveList;

  private isPdf = true;
  private isOneFile = false;
  private zeroRecordsResponse = false;
  private isLoadingReport = false;

  get isErstInfoReport() {
    return this.listModel.type === 'erst_info_list';
  }

  get isSortimentsmatrixMFH() {
    return this.listModel.type === 'sortimentsmatrix_MFH';
  }

  async created() {
    await this.getSaveListData();
  }

  async getSaveListData() {
    await this.actionGetSaveList(this.listModel.id)
      .then(async (result: any) => {
        this.model = result;
        await this.getSuppliers();
      })
      .catch((err: any) => {
        logger.error(err);
      });
  }

  private async getSuppliers() {
    const payload: ReportFilterData = rfd.defaultData();

    this.preparePayload(payload);

    await this.actionGetArticlesSuppliers(payload)
      .then((result: any) => {
        this.suppliersBackend = result;
        this.populateSuppliersTable();
      })
      .catch((err: any) => {
        logger.error(err);
      });
  }

  populateSuppliersTable() {
    this.suppliersBackend.forEach((el: any) => {
      this.suppliers.push({
        name: `${el.lieferantenNummer} ${el.lieferantName}`,
        count: el.count,
        number: el.lieferantenNummer, // send to backend to filter `Suppliers` for report
        status: 'aktiv',
        updateAt: el.lastUpdated // new Date().toISOString(),
      });
    });
  }

  search = '';
  singleSelect = false;
  selected = [];

  suppliersBackend = [];
  suppliers: any = [];

  get headers() {
    let headers: {
      text: string | any;
      value: string;
      sortable?: boolean;
      width?: string;
    }[] = [
      { text: this.$t('supplier_name'), value: 'name' },
      { text: this.$t('count'), value: 'count' },
      { text: this.$t('status'), value: 'status' },
      { text: this.$t('update_at'), value: 'updateAt' },
    ];
    return headers;
  }

  async clickSave() {
    const result = await this.observerReportDialogForm.validate();

    const payload: ReportFilterData = rfd.defaultData();
    this.preparePayload(payload);
    // use `any` to avoid `System.ArgumentNullException: Value cannot be null. (Parameter 'request')` error
    const reportDownloadPayload: any = this.prepareReportDownloadPayload();
    if (result) {
      this.isLoadingReport = true;

      if (this.isOneFile) {
        this.getReport(payload)
          .then(async (result: any) => {
            await this.actionUpdateReportDownload(reportDownloadPayload);
          })
          .finally(() => {
            this.isLoadingReport = false;
          })
          .catch((err) => {});
      } else {
        var promiseAll: any = [];
        this.selected.forEach(async (element: any) => {
          payload.lieferantenNummer = element.number;
          promiseAll.push(this.getReport(payload));
        });

        await Promise.all(promiseAll)
          .then(async () => {
            await this.actionUpdateReportDownload(reportDownloadPayload);
          })
          .finally(() => {
            this.isLoadingReport = false;
          });
      }
    }
  }
  prepareReportDownloadPayload() {
    // use `any` to avoid `System.ArgumentNullException: Value cannot be null. (Parameter 'request')` error for `ReportDownload`
    const result: any = {};
    result.id = CONST.emptyGuid;
    result.saveListId = this.model.id;
    if (this.isSortimentsmatrixMFH) {
      this.isPdf = false;
    }

    result.outputType = this.isPdf ? 'PDF' : 'Excel';
    return result;
  }

  private async getReport(payload: ReportFilterData) {
    await this.actionGetArticlesReport(payload).catch((err: any) => {
      logger.error(err);
    });
  }

  private preparePayload(payload: any) {
    this.selected.forEach((el: any) => {
      payload.lieferantenNummern.push(el.number);
    });
    if (this.model?.warengruppen) {
      const groups = this.model?.warengruppen.split(',');
      payload.warengruppen = groups;
    }

    if (this.model?.articlesMarkerNames?.length > 0) {
      payload.articlesMarkerNames = this.model?.articlesMarkerNames;
    }
    if (this.model?.modelMarkerNames?.length > 0) {
      payload.modelMarkerNames = this.model?.modelMarkerNames;
    }
    if (this.model?.responsibleNames?.length > 0) {
      payload.responsibleNames = this.model?.responsibleNames;
    }

    if (this.model?.modelstatus) {
      const modelstatuses = this.model?.modelstatus.split(',');
      payload.modelstatuses = modelstatuses;
    }
    if (this.model?.vArtikelstatus) {
      const vArtikelstatuses = this.model?.vArtikelstatus.split(',');
      payload.vArtikelstatuses = vArtikelstatuses;
    }
    if (this.model?.vAuspraegungStatus) {
      const vAuspraegungStatuses = this.model?.vAuspraegungStatus.split(',');
      payload.vAuspraegungStatuses = vAuspraegungStatuses;
    }

    payload.name = this.model.name;
    payload.title = this.model.title;
    payload.isMaintained = this.model.vmEgepflegt;
    payload.reportType = this.model.type;
    payload.sort = this.model.sort;
    payload.isPdf = !this.isSortimentsmatrixMFH ? this.isPdf : false;
    payload.IsOneFileForSelectedSuppliers = this.isOneFile;
    payload.isShowHerstellerArtikelNummer = this.model.isShowHerstellerArtikelNummer;
  }

  clickClose() {
    this.$emit('click:close');
  }
}
